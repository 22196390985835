<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <div class="home-registry-wrapper">
          <EnterpriseUserComponent 
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../../components/Enterprise/leftmenu/LeftMenu.vue";
import Navbar from "../../../components/Enterprise/header/Navbar.vue";
import EnterpriseUserComponent from "../../../components/admin/user/EnterpriseUserComponent.vue";
import { mapGetters } from "vuex";
// import axios from "axios";


export default {
  name: "EnterpriseUserView",
  data() {
    return {
      fullPage: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {

  },

  components: {
    Navbar,
    LeftMenu,
    EnterpriseUserComponent,
  },
};
</script>
<style scoped>
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
</style>
